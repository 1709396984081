import React, {useContext, useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import db from './db'
import {MyContext} from "../contexts/MyContext";
import axios from "axios";
//import axios from "axios";



function DialogAufbau(props) {

    const {rootState} = useContext(MyContext);
    const {theUser} = rootState;

    //const [step1, setStep1Saved] = useState("");
    const [step1Class, setStep1Class] = useState("");
    const [step2Class, setStep2Class] = useState("hide");
    const [step3Class, setStep3Class] = useState("hide");

    const [btnDisabledStep2, setDisabledStep2] = useState(true);
    const [aufbauGewichte, setaufbauGewichte] = useState(0);
    const [aufbauDatum, setaufbauDatum] = useState('');
    const [bemerkung, setBemerkung] = useState("");

    const [bildvorhanden, setbildvorhanden] = useState(false);

    const [openFile, setDialogOpen] = React.useState(false);
    const handleClickDialogOpen = () => {
        setDialogOpen(true);
        setbildvorhanden(props.bildvorhanden);
        setDisabledStep2(true);
        setaufbauGewichte(0);
        setStep3Class("hide");

        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Monate beginnen bei 0
        const year = today.getFullYear();
        const formattedDate = `${day}.${month}.${year}`;
        setaufbauDatum(formattedDate);

        setOpen(false);
        if (!props.bildvorhanden){
            seterrorOpen(true);
        } else {
            seterrorOpen(false);
        }
    };

    const setGewichteAufbau = (event) => {

        console.log("bildvorhanden: ",bildvorhanden);

        if (event.target.value !=="") {
            if (bildvorhanden){
                setDisabledStep2(false);
            } else {
                seterrorOpen(true);
            }
            setaufbauGewichte(event.target.value);
        } else {
            setDisabledStep2(true);
        }
    }

    const handleDialogClose = () => {
        //setStep1Saved("");
        setStep1Class("");
        setStep2Class("hide");
        setDialogOpen(false);
    };

    function saveStep1True() {
        //setStep1Saved("1");
        setStep1Class("hide");
        setStep2Class("");
    }
    function saveStep1False() {
        //setStep1Saved("0");
        setStep1Class("hide");
        setStep3Class("");
    }

    const [open, setOpen] = useState(false);
    const [errorOpen, seterrorOpen] = useState(false);

    const updateSchilddaten = async (id, newData) => {
        try {
            await db.schilder.update(id, newData);
            console.log("Datensatz wurde aktualisiert.");
        } catch (error) {
            console.error(error);
        }
    };

    const auftragAbschliessen = async ()=>{
        setOpen(true);
        // Auftragsdaten lokal speichern
        // Aktuelles Datum:
        let date = new Date();
        let datum = String(date.getDate()).padStart(2, '0') + '.' + String(date.getMonth() + 1).padStart(2, '0') + '.' + date.getFullYear();
        handleAuftragChange();
        await db.bemerkungen.add({tourid:props.tourid, vid:props.IDVermietung, uid:theUser.id, user:theUser.anzeigename, bemerkung:bemerkung, uebertragen:"", auftragsart:"A"});
        updateSchilddaten(parseInt(props.vid), { aufbaudatum: datum, aufbaudatumValue: datum, Gewicht_Aufbau: parseInt(aufbauGewichte), fm: "auftragabgeschlossen", istuebertragen:"" });
        transferBemerkungen(bemerkung);


        var bauschild_bemerkungen = [];
        const databemerkungen = await db.bemerkungen.where('vid').equals(props.IDVermietung).toArray();
        databemerkungen.forEach((bemerkung) => {
            bauschild_bemerkungen.push(bemerkung.user+": "+bemerkung.bemerkung);
        });

        props.setBemerkungen(bauschild_bemerkungen);

    };

    const setBemerkungAufbau = (event) => {
        if (bildvorhanden) {
            if (event.target.value !== "") {
                setDisabledStep2(false);
                setBemerkung(event.target.value);
            }
        }
    }

    const auftragAbschliessenOhneAufbau = async () => {
        try {
            setOpen(true);
            // Auftragsdaten lokal speichern
            //handleAuftragChange();
            await db.bemerkungen.add({tourid:props.tourid, vid:props.IDVermietung, uid:theUser.id, user:theUser.anzeigename, bemerkung:bemerkung, uebertragen:"", auftragsart:"A"});
            console.log("Datensatz wurde aktualisiert.");

            updateSchilddaten(parseInt(props.vid), { fm: "auftragnichtabgeschlossen" });
            await transferBemerkungen(bemerkung);
            await props.transferBilder();

            var bauschild_bemerkungen = [];
            const databemerkungen = await db.bemerkungen.where('vid').equals(props.IDVermietung).toArray();
            databemerkungen.forEach((bemerkung) => {
                bauschild_bemerkungen.push(bemerkung.user+": "+bemerkung.bemerkung);
            });

            props.setBemerkungen(bauschild_bemerkungen);

        } catch (error) {
            console.error(error);
        }
    };


    const transferBemerkungen = async (bemerkung) => {
        var vid = parseInt(props.IDVermietung);
        try {
            var databemerkungen = await db.bemerkungen.where('vid').equals(parseInt(vid)).toArray();
            axios.post("https://api.bauschild-portal.de/restful-api-webapp/transferBemerkungen.php", {
                tourid:props.tourid,
                vid:vid,
                userid:theUser.id,
                databemerkung:bemerkung,
                auftragsart:"A"
            })
                .then((response) => {
                    console.log("Bemerkungen übertragen");
                    console.log(response.data);
                });
        } catch (error) {
            console.error(error);
        }
    }




    function handleAuftragChange() {
        props.onValueChange(true);
    }

    return (
            <>
                <Dialog
                    open={openFile}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Aufbau - Auftrag abschließen
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div id="step1" className={step1Class}>
                                <div className="griddialog">
                                    <div>Konnte das Schild aufgebaut werden?</div>
                                    <div>
                                        <Button variant="outlined" size="small" onClick={saveStep1True}>Ja</Button>
                                        <Button variant="outlined" size="small" onClick={saveStep1False}>Nein</Button>
                                    </div>
                                </div>
                            </div>
                            <div id="step2" className={step2Class}>
                                <div className="griddialog mb">
                                    <div>Wie viele Gewichte bleiben beim Schild?</div>
                                    <div>
                                        <TextField
                                            id="aufbauGewichte"
                                            required
                                            variant="outlined"
                                            defaultValue=""
                                            size="small"
                                            type="number"
                                            onChange={setGewichteAufbau}
                                        />
                                    </div>
                                </div>
                                <div className="griddialog mb">
                                    <div>Datum Aufbau</div>
                                    <TextField
                                        id="aufbauDatum"
                                        disabled
                                        variant="outlined"
                                        defaultValue={aufbauDatum}
                                        size="small"
                                    />
                                </div>
                                <div className="mb">
                                    <div>
                                        <TextField
                                            id="bemerkung"
                                            label="Deine Bemerkung"
                                            multiline
                                            rows={4}
                                            inputProps={{
                                                cols: 100
                                            }}
                                            defaultValue=""
                                            onChange={setBemerkungAufbau}
                                        />
                                    </div>
                                </div>

                                <div className="mb">
                                    <Button variant="contained" onClick={auftragAbschliessen} disabled={btnDisabledStep2}>Auftrag jetzt abschließen</Button>
                                </div>

                                <Collapse in={open}>
                                    <Alert

                                    >
                                        Auftrag erfolgreich abgeschlossen und gespeichert!
                                    </Alert>
                                </Collapse>

                                <Collapse in={errorOpen}>
                                    <Alert severity="error">
                                        Du hast noch kein Foto hochgeladen oder es wurde noch nicht übertragen!
                                    </Alert>
                                </Collapse>
                            </div>


                            <div id="step3" className={step3Class}>
                                <div className="mb">
                                    <div className="mb">Bitte gib einen Grund ein, warum das Schild nicht aufgebaut werden konnte.</div>
                                    <div>
                                        <TextField
                                            id="bemerkung"
                                            label="Deine Bemerkung"
                                            multiline
                                            rows={4}
                                            inputProps={{
                                                cols: 100
                                            }}
                                            defaultValue=""
                                            onChange={setBemerkungAufbau}
                                        />
                                    </div>
                                </div>

                                <div className="mb">
                                    <Button variant="contained" onClick={auftragAbschliessenOhneAufbau} disabled={btnDisabledStep2}>Auftrag jetzt abschließen</Button>
                                </div>

                                <Collapse in={open}>
                                    <Alert

                                    >
                                        Auftrag gespeichert!
                                    </Alert>
                                </Collapse>

                                <Collapse in={errorOpen}>
                                    <Alert severity="error">
                                        Du hast noch kein Foto hochgeladen oder es wurde noch nicht übertragen!
                                    </Alert>
                                </Collapse>


                            </div>


                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose}>Fenster schließen</Button>

                    </DialogActions>
                </Dialog>
                <Button variant="contained" onClick={handleClickDialogOpen}>Aufbau - Auftrag bearbeiten / abschließen</Button>
            </>
        );

}

export default DialogAufbau;
