import React, { useContext, useState, useEffect } from "react";

import {Link} from "react-router-dom";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import {MyContext} from '../contexts/MyContext'
import Login from "./Login";
import db from './db'

function Tour(){
    const {rootState} = useContext(MyContext);
    const {isAuth,showLogin,theUser} = rootState;
    const urlParams = new URLSearchParams(window.location.search);
    const tourid = urlParams.get('id');
    const [geladenClass, setgeladenClass] = useState("hide");
    const [neuladenClass, setneuladenClass] = useState("hide");
    const [nichtgeladenClass, setnichtgeladenClass] = useState("");


    useEffect(() => {
        datenVorhanden();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const datenVorhanden = async () => {
        const data = await db.materiallisten.where('tourid').equals(tourid).first();
        if (data === undefined || data===""){
            getSchilder();
        } else {
            setnichtgeladenClass('hide');
            setneuladenClass('');
        }
    }

    const deleteSchilder = async () => {
        try {
            await db.schilder.where('tourid').startsWithIgnoreCase(tourid).delete();
            await db.materiallisten.where('tourid').startsWithIgnoreCase(tourid).delete();
            await db.bemerkungen.where('tourid').startsWithIgnoreCase(tourid).delete();
            await db.bilder.where('tourid').startsWithIgnoreCase(tourid).delete();
            getSchilder();
        } catch (error) {
            console.error(error);
        }
    }

    const loginToken = localStorage.getItem('loginToken');
    const URL = "https://api.bauschild-portal.de/restful-api-webapp/tour.php?id="+tourid+"&t="+loginToken;
    const getSchilder = async () => {const response = await fetch(URL);

        const jsonData = await response.json();

        console.log(jsonData);

        var materialliste = jsonData.tourdaten.Materialliste;

        db.materiallisten.add({
            tourid:tourid,
            Materialliste:materialliste,
            LogAngelegt:jsonData.tourdaten.LogAngelegt,
            Tourname:jsonData.tourdaten.Tourname,
        });

        // Liste der Standorte erstellen und in LocalSrorage speichern
        var obj = jsonData.schilder;
        var standorte = [];
        var I = 0;
        localStorage.removeItem('standorte');
        Object.keys(obj).forEach(function(key) {
            if (obj[key].latlong!==",") {
                console.log(obj[key]);
                standorte[I] = [obj[key].Typ, obj[key].latlong, obj[key].bauschildnummer, obj[key].Nr];
                I++;
            }
            // Schilddaten in lokale DB speichern
            db.schilder.add({
                id:obj[key].id,
                Typ:obj[key].Typ,
                Zusatz:obj[key].Zusatz,
                Nr:obj[key].Nr,
                bauschildnummer:obj[key].bauschildnummer,
                schildnr:obj[key].schildnr,
                bauvorhaben_name:obj[key].bauvorhaben_name,
                bauschild_bemerkungen:obj[key].bauschild_bemerkungen,
                what3words:obj[key].what3words,
                latlong:obj[key].latlong,
                bauvorhaben:obj[key].bauvorhaben,
                aufbaudatum:obj[key].aufbaudatum,
                abbaudatum:obj[key].abbaudatum,
                mietende:obj[key].mietende,
                aufbaudatumValue:obj[key].aufbaudatumValue,
                abbaudatumValue:obj[key].abbaudatumValue,
                _ID_Vermietung:obj[key]._ID_Vermietung,
                css:obj[key].css,
                kunde:obj[key].kunde,
                foto:obj[key].foto,
                design:obj[key].design,
                Gewicht_Abbau:obj[key].Gewicht_Abbau,
                Gewicht_Aufbau:obj[key].Gewicht_Aufbau,
                tourid:tourid,
                fm:obj[key].fm,
                istuebertragen:obj[key].istuebertragen,
                WebPortalId:obj[key].WebPortalId,
                mappe:obj[key].mappe,
                dokumente:obj[key].dokumente,
                Bauleiter_Polier:obj[key].Bauleiter_Polier,
                Bauleiter_Polier_Tel:obj[key].Bauleiter_Polier_Tel,
            });

            var zusatzbemerkungen = JSON.parse(obj[key].vm_bemerkungen);


            Object.keys(zusatzbemerkungen).forEach(function(key) {
                if (zusatzbemerkungen[key].bemerkung !== "")
                db.bemerkungen.add({tourid:tourid, vid:zusatzbemerkungen[key].vid, uid:zusatzbemerkungen[key].uid, user:zusatzbemerkungen[key].user, bemerkung:zusatzbemerkungen[key].bemerkung, uebertragen:""});
            });

            if (obj[key].Bemerkung_Aufbau !== "")
                db.bemerkungen.add({tourid:tourid, vid:obj[key]._ID_Vermietung, uid:0, user:"FM", bemerkung:obj[key].Bemerkung_Aufbau, uebertragen:""});
            if (obj[key].Bemerkung_Abbau !== "")
                db.bemerkungen.add({tourid:tourid, vid:obj[key]._ID_Vermietung, uid:0, user:"FM", bemerkung:obj[key].Bemerkung_Abbau, uebertragen:""});
            if (obj[key].Bemerkung_Service !== "")
                db.bemerkungen.add({tourid:tourid, vid:obj[key]._ID_Vermietung, uid:0, user:"FM", bemerkung:obj[key].Bemerkung_Service, uebertragen:""});



            // Bilddaten speichern - vorbelegen /// Im nächsten Schritt diese Daten noch über die API laden
            db.bilder.add({tourid:tourid, vid:obj[key]._ID_Vermietung, uid:theUser.id, user:theUser.anzeigename, bild1:obj[key].foto, bild2:"", bild3:"", bild4:""});

        });
        localStorage.setItem('standorte', JSON.stringify(standorte));
        setgeladenClass("");
        setnichtgeladenClass("hide");

    };


    function tourdatenNeuLaden(){
        var x = window.confirm("ACHTUNG: Die vorhandenen lokalen Daten werden überschrieben! Möchtest du das?");
        if (x){
            setgeladenClass("hide");
            setneuladenClass("hide");
            setnichtgeladenClass("");
            deleteSchilder();
        }
    }


    if(isAuth) {
        return (
            <div className="content">
                <div className="zentriert">
                    <h2>Tourdaten übertragen</h2>

                    <div className={nichtgeladenClass}>Bitte warten ... es kann 1 - 2 Minuten dauern.</div>

                    <div className={geladenClass}>
                        Die Daten wurden erfolgreich übertragen<br /><br />

                        <ButtonGroup
                            orientation="vertical"
                            aria-label="vertical contained button group"
                            variant="contained"
                        >

                            <Button component={Link} to={`/`} variant="outlined" color="primary">
                                Zurück zu Home
                            </Button>

                            <Button component={Link} to={`/tour/?id=${tourid}`} variant="contained" color="primary">
                                Zur Tour {tourid}
                            </Button>

                            <Button  onClick={tourdatenNeuLaden} variant="outlined" color="primary">
                                Tourdaten erneut übertragen
                            </Button>
                        </ButtonGroup>
                    </div>


                <div className={neuladenClass}>
                    Die Daten wurden erfolgreich übertragen<br /><br />

                    <ButtonGroup
                        orientation="vertical"
                        aria-label="vertical contained button group"
                        variant="contained"
                    >

                        <Button component={Link} to={`/`} variant="outlined" color="primary">
                            Zurück zu Home
                        </Button>

                        <Button component={Link} to={`/tour/?id=${tourid}`} variant="contained" color="primary">
                            Zur Tour {tourid}
                        </Button>

                        <Button  onClick={tourdatenNeuLaden} variant="outlined" color="primary">
                            Tourdaten erneut übertragen
                        </Button>
                    </ButtonGroup>
                </div>

                </div>

            </div>
        )
    }
    else if(showLogin){
        return <Login/>;
    }
}

export default Tour;
