import React, { useContext, useState, useEffect } from "react";
import {useHistory} from "react-router-dom";
import {MyContext} from '../contexts/MyContext'
import Login from "./Login";
import TTRoutemodal from "./TTRoutemodal";
import db from "./db";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';



function Tour(){
    const {rootState} = useContext(MyContext);
    const {isAuth,showLogin} = rootState;
    const [schilder, setSchilder] = useState([]);
    const [tourdaten, setTourdaten] = useState("");
    const [materialdaten, setMaterialdaten] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const navigate = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const tourid = urlParams.get('id');

    useEffect(() => {
        //getSchilder();
        getSchilderFromDB();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getSchilderFromDB = async () => {
        const data = await db.materiallisten.where('tourid').equals(tourid).first();

        if (data===undefined) navigate.push("/tourladen/?id="+tourid);

        setTourdaten(data);
        var materialliste = data.Materialliste;
        materialliste = materialliste.split("\r");
        setMaterialdaten(materialliste);

        const dataschilder = await db.schilder.where('tourid').startsWithIgnoreCase(tourid).toArray();
        //console.log(dataschilder);

        var standorte = [];
        var I = 0;
        localStorage.removeItem('standorte');
        Object.keys(dataschilder).forEach(function(key) {
            if (dataschilder[key].latlong!==",") {
                standorte[I] = [dataschilder[key].Typ, dataschilder[key].latlong, dataschilder[key].bauschildnummer, dataschilder[key].Nr];
                I++;
            }
        });
        localStorage.setItem('standorte', JSON.stringify(standorte));

        setSchilder(dataschilder);
        setShowModal(false);
    }

    function goToSchild(id,vid) {
        navigate.push("/tourdaten/?id="+id+"&tourid="+tourid+"&vid="+vid);
    }

    function checkAuftrag(fm,istuebertragen,id){
        if (fm==="auftragabgeschlossen" && istuebertragen===1){
            return <td className="text-center gruen"><CheckCircleIcon /></td>
        } else if (fm==="auftragabgeschlossen" && istuebertragen===""){
            return <td className="text-center grau"><CheckCircleIcon /></td>
        } else if (fm==="auftragnichtabgeschlossen" && istuebertragen===""){
            return <td className="text-center grau"><CheckCircleIcon /></td>
        } else {
            return <td></td>
        }
    }

    if(isAuth) {
        return (
            <div className="content">
                <div className="gridtop">
                    <h2>Tourdaten</h2>
                    <div>
                        {(() => {
                            return (<TTRoutemodal showModal={showModal} />)
                        })()}
                    </div>
                </div>

                <div className="grid2">
                    <div className="gridzeile mr">Tour {tourid}</div>
                    <div className="gridzeile">{tourdaten.Tourname}</div>
                </div>

                <div className="grid1">
                    <div className="divtitel">Materialliste:</div>
                    <ul className="liste">
                        {
                            materialdaten.map((material,index) => (
                                <li key={index}>{material}</li>
                            ))
                        }
                    </ul>
                </div>

                <div className="tabelle">
                    <table>
                        <thead>
                        <tr>
                            <th>T</th>
                            <th>Nr</th>
                            <th>I</th>
                            <th>Akte</th>
                            <th>Typ</th>
                            <th>G.Au</th>
                            <th>G.Ab</th>
                            <th>Kunde</th>
                            <th>Ort</th>
                            <th>Au.D</th>
                            <th>Ab.D</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            schilder.map(schild => (
                                <tr key={schild.id} onClick={() => goToSchild(schild.id,schild._ID_Vermietung)} >
                                    <td className={schild.css}>{schild.Typ}</td>
                                    <td className={schild.fm}>{schild.Nr}</td>
                                    <td className={`text-center ${schild.fm}`}>{schild.Zusatz}</td>
                                    <td className={`umbruch ${schild.fm}`}>{schild.bauschildnummer}</td>
                                    <td className={`umbruch ${schild.fm}`}>{schild.schildnr}</td>
                                    <td className={`text-center ${schild.fm}`}>{schild.Gewicht_Aufbau}</td>
                                    <td className={`text-center ${schild.fm}`}>{schild.Gewicht_Abbau}</td>
                                    <td className={schild.fm}>{schild.kundennummer} {schild.kunde}</td>
                                    <td className={schild.fm}>{schild.bauvorhaben}</td>
                                    <td className={schild.fm}>{schild.aufbaudatum}</td>
                                    <td className={schild.fm}>{schild.abbaudatum}</td>
                                    {checkAuftrag(schild.fm, schild.istuebertragen,schild.id)}
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
    else if(showLogin){
        return <Login/>;
    }
}

export default Tour;
